.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@media print {
	body * {
		visibility: hidden;
	}
	.printable,
	.printable * {
		visibility: visible;
	}
	.printable {
		position: absolute;
		left: 0;
		top: 0;
	}
}

input::-webkit-credentials-auto-fill-button,
input::-webkit-contacts-auto-fill-button {
	display: none !important;
	-webkit-appearance: none !important;
	visibility: hidden !important;
}

input::-webkit-autofill,
input::-webkit-autofill:hover,
input::-webkit-autofill:focus,
input::-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0px 1000px white inset !important;
	box-shadow: 0 0 0px 1000px white inset !important;
	-webkit-text-fill-color: #000 !important;
}
